const text = (orgId, authKey) =>
  `
import requests
import json

url = "https://api.lazarusforms.com/api/forms/invoices"

payload = json.dumps({
  "base64": "base64_string",
  "fileId": "fileId_HERE | Optional",
  "metadata": {"metadata_HERE | Optional"},
  "webhook": "webhook_HERE | Optional"
})
headers = {
  'orgId': ${orgId},
  'authKey': ${authKey},
  'Content-Type': 'application/json'
}

response = requests.request("POST", url, headers=headers, data=payload)

print(response.text)
 `

export default text
