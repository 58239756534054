const text = (orgId, authKey) =>
  `class Program
 {
     private static string endpoint = "https://api.lazarusforms.com/api";
     private static string orgId = ${orgId};
     private static string authKey = ${authKey};

     static async Task Main(string[] args)
     {
         await Test();
     }

     private static async Task Test()
     {
         try
         {
             var client = new RestClient(endpoint);
             var request = new RestRequest("/forms/receipts/zip", Method.Post);
             request.Timeout = -1;
             request.AddHeader("orgId", ${orgId});
             request.AddHeader("authKey",  ${authKey});
             var body = @"{""inputUrl"":""https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"",""fileId"":""abc"",""metadata"":{},""webhook"":""google.com""}";
             request.AddParameter("application/json", body,  ParameterType.RequestBody);
             RestResponse response = await client.ExecutePostAsync(request);
             Console.WriteLine(response.Content);
        }
         catch(Exception ex)
         {
             Console.WriteLine(ex.Message);
         }
     }
 }`

export default text
