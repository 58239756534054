const text = (orgId, authKey) =>
  `
import requests

url = "https://api.lazarusforms.com/api/forms/invoices"

payload={}

files=[
  ('file',('file_name',open('path_to_file','rb'),'application/octet-stream'))
]

headers = {
  'orgId': ${orgId},
  'authKey': ${authKey}
}

response = requests.request("POST", url, headers=headers, data=payload, files=files)

print(response.json())
 `

export default text
