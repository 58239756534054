import React, {useEffect, useState} from 'react'
import {Icon, Button, Input, Modal, Switch} from '@lazarusai/forms-ui-components'
import Helpers from '../Helpers.js'
import '../styles/PermissionsModal.css'

export default function PermissionsModal({
  theme = 'light',
  switchType = 'switch',
  onCancel,
  onSave,
  isVisible,
  user,
  id = user,
  name,
  height = '95vh',
  roles,
  permissions,
  roleDescriptions = '',
  permissionsUser, // only for setting existing user permission
}) {
  // initial state of user's permissions
  const initialUserPermissions = {
    inviteUser: false,
    manageBilling: false,
    resetAuthKey: false,
    setPermissions: false,
    useDemoCredits: false,
    viewAuditLogs: false,
    viewCredentials: false,
    viewLogData: false,
    viewLogs: false,
  }
  // preset permissions for each named role (Basic, Intermediate, Advanced)
  const [rolePresets, setRolePresets] = useState({})

  const [rolesShowing, setRolesShowing] = useState(false)
  // named role selected by user
  const [selectedRole, setSelectedRole] = useState('')
  const [currentPermissionValues, setCurrentPermissionValues] = useState({
    inviteUser: false,
    manageBilling: false,
    resetAuthKey: false,
    setPermissions: false,
    useDemoCredits: false,
    viewAuditLogs: false,
    viewCredentials: false,
    viewLogData: false,
    viewLogs: false,
  })

  useEffect(() => {
    roles.forEach((role) => {
      rolePresets[role] = {}
    })
    Object.keys(permissions).forEach((permission) => {
      initialUserPermissions[permission] = permissions[permission].default
      roles.forEach((role) => {
        rolePresets[role][permission] = permissions[permission]['roles'].includes(role)
        // rolePresents = {
        //   'admin': {
        //        viewLogs: true,
        //        ...,
        //    },
        //   'intermediate': ...
        // }
      })
    })
    setRolePresets(rolePresets)
  }, [])

  useEffect(() => {
    /* role is set programmatically based on selected permissions and vice versa */
    if (permissionsUser) {
      // set permissions take priority over role based permissions
      if (permissionsUser.permissions) {
        setCurrentPermissionValues(permissionsUser.permissions)
      } else if (permissionsUser.role && rolePresets[permissionsUser.role]) {
        setCurrentPermissionValues(rolePresets[permissionsUser.role])
      }
    }
  }, [permissionsUser])

  useEffect(() => {
    let isCustom = true
    Object.keys(rolePresets).forEach((role) => {
      const isEqual = Object.keys(rolePresets[role]).every((perm) => {
        return rolePresets[role][perm] === currentPermissionValues[perm]
      })
      if (isEqual) {
        setSelectedRole(role)
        isCustom = false
      }
    })
    if (isCustom) {
      setSelectedRole('')
    }
  }, [initialUserPermissions, selectedRole, currentPermissionValues])

  return (
    <Modal
      isVisible={isVisible}
      showCancel={false}
      showConfirm={false}
      showClose={false}
      onConfirm={onSave}
      onCancel={onCancel}
      theme={theme}
      height={height}
      className={theme}
      outsideClickDismisses={true}
    >
      <div className='content'>
        <header>
          <div className='header'>
            <h2>{name || user}</h2>
            {name && user && <p>{user}</p>}
          </div>
          <div className='select'>
            <span>User Role:</span>
            <Input
              inputType='select'
              placeholder={'Select'}
              theme={theme}
              style={{margin: '0'}}
              options={roles.map((role) => Helpers.capitalizeFirstLetter(role))}
              value={Helpers.capitalizeFirstLetter(selectedRole)}
              onChange={(e) => {
                const val = e.target.value.toLowerCase()
                setSelectedRole(val)
                setCurrentPermissionValues(rolePresets[val])
              }}
              iconLeft={<Icon icon='list-outline' />}
              required
            />
            {roleDescriptions &&
              <a onClick={() => setRolesShowing(!rolesShowing)}>
                {!rolesShowing ? 'Show role descriptions' : 'Hide role descriptions'}
              </a>
            }
          </div>
        </header>
        <div>
          {rolesShowing && (
            <div className='description'> {roleDescriptions} </div>
          )}
        </div>
        <div className='permissionsGrid'>
          {Object.keys(permissions).map((permission) => {
            const isChecked =
              currentPermissionValues?.[permission] === undefined ?
                false :
                currentPermissionValues?.[permission]
            return (
              <div className='permission' key={permission}>
                <><span className='permissionHeader'>
                  {permissions[permission].icon}
                  {Helpers.capitalizeFirstLetter(permissions[permission].roles[0])}
                </span><Switch
                  type={switchType}
                  onChange={() => {
                    setCurrentPermissionValues({
                      ...currentPermissionValues,
                      [permission]: !currentPermissionValues[permission],
                    })
                  } }
                  value={isChecked}
                  checked={isChecked}
                  theme={theme}
                  label={permissions[permission].description} /></>
              </div>)
          },
          )}
        </div>
        <div>
          <Button
            icon={<Icon icon='save-outline' />}
            iconPosition='right'
            iconJustify='edge'
            onClick={() => onSave(currentPermissionValues, id, selectedRole.length ? selectedRole : 'Custom')}
            type='8'
            theme={theme}
            text='Confirm'
            // disabled={!selectValue}
          />
          <Button
            icon={<Icon icon='close-outline' />}
            iconPosition='right'
            iconJustify='edge'
            onClick={onCancel}
            type='10'
            theme={theme}
            text='Cancel'
          />
        </div>
      </div>
    </Modal>
  )
}
