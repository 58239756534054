const text = (orgId, authKey) =>
  `
var myHeaders = new Headers();
myHeaders.append("orgId", ${orgId});
myHeaders.append("authKey", ${authKey});
myHeaders.append("Content-Type", "application/json");

var raw = JSON.stringify({
  "inputUrl": "inputUrl_HERE",
  "fileId": "fileId_HERE | Optional",
  "metadata": {"metadata_HERE | Optional"},
  "question": "question_HERE",
  "webhook": "webhook_HERE | Optional"
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch("https://api.lazarusforms.com/api/rikai/custom/your_custom_model_id_HERE", requestOptions)
  .then(response => response.text())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));
 `

export default text
