import React, {useEffect, useState, useRef} from 'react'
import {connect} from 'react-redux'

import {Table, TableEntry, Modal2, Icon, Select} from '@lazarusai/forms-ui-components'
import Helpers from '../Helpers'
import Code from './Code'

import {storePayload} from '../actions/storePayload'

// Model Icons
import Lazarus_L from '../images/lazarus_L.svg'
import Lazarus_Summary from '../images/RikAI_Summarizer.svg'
import Lazarus_Rikai from '../images/rikai.svg'
import Lazarus_Riky from '../images/RIKY.svg'
import Lazarus_Rikai_OCS from '../images/RikAI_OCS.svg'
import Lazarus_Forms from '../images/forms_icon.svg'

import '../styles/Models.css'
import ModelInformation from './ModelInformation'

function Models(props) {
  const [modelEntries, setModelEntries] = useState([])
  const [selectTime, setSelectTime] = useState(Date.now())
  const [selectedList, _setSelectedList] = useState([])
  const [isCompareModalShowing, setIsCompareModalShowing] = useState(false)
  const selectedListRef = useRef([])
  const [filterCriteria, setFilterCriteria] = useState('priority')
  const [isFilteringUp, setIsFilteringUp] = useState(true)

  function onClickCriteria(criteria) {
    if (criteria === filterCriteria) {
      setIsFilteringUp(!isFilteringUp)
    } else {
      setFilterCriteria(criteria)
      setIsFilteringUp(true)
    }
  }

  function sortByCriteria(modelKeys) {
    if (!filterCriteria) {
      return modelKeys
    } else {
      modelKeys.sort((aKey, bKey) => {
        const aVal = getCriteriaValue(filterCriteria, aKey) || 0
        const bVal = getCriteriaValue(filterCriteria, bKey) || 0
        if (aVal < bVal) {
          return 1
        } else if (aVal > bVal) {
          return -1
        } else {
          return 0
        }
      })
      return isFilteringUp ? modelKeys: modelKeys.reverse()
    }
  }

  function getCriteriaValue(criteria, key) {
    const criteriaPath = criteria.split('|')
    let val = props.modelInformation?.[key]?.public?.metadata
    for (let i = 0; i < criteriaPath.length; i++) {
      val = val[criteriaPath[i]]
    }
    return val
  }

  function setSelectedList(val) {
    const newVal = JSON.parse(JSON.stringify(val))
    _setSelectedList(newVal)
    selectedListRef.current = newVal
    setSelectTime(Date.now())
  }

  function getImageForModel(model) {
    if (!model?.public?.metadata?.name) {
      return Lazarus_Forms
    }
    switch (true) {
      case !model:
        return Lazarus_L
      case model?.public?.metadata?.name.toLowerCase().includes('summar'):
        return Lazarus_Summary
      case model?.public?.metadata?.name.toLowerCase().includes('checkbox') || model?.public?.metadata?.name.toLowerCase().includes('ocs'):
        return Lazarus_Rikai_OCS
      case model?.public?.metadata?.name.toLowerCase().includes('riky'):
        return Lazarus_Riky
      case model?.public?.metadata?.name.toLowerCase().includes('rikai'):
        return Lazarus_Rikai
      default:
        return Lazarus_Forms
    }
  }

  useEffect(() => {
    if (props.modelInformation) {
      setModelEntries(sortByCriteria(Object.keys(props.modelInformation)).map((modelKey, i) => {
        return (
          <TableEntry
            key={`${modelKey}-${i}`}
            isSelected={selectedList.includes(modelKey)}
            onClick={() => {
              if (selectedList.includes(modelKey) && selectTime) {
                selectedList.splice(selectedList.indexOf(modelKey), 1)
                setSelectedList(selectedList)
              } else {
                selectedList.push(modelKey)
                while (selectedList.length >= 4) {
                  selectedList.shift()
                }
                setSelectedList(selectedList)
              }
            }}
            content={[
              <div
                key={`${modelKey}-${i}-avatar`}
                className='small-col'
              >
                <div
                  key={`${modelKey}-${i}-${(selectedList.includes(modelKey)) ? 'highlight-avatar': 'avatar'}`}
                  className={`icon cursor-pointer`}
                >
                  <img src={getImageForModel(props.modelInformation?.[modelKey])}/>
                </div>
              </div>,
              <div
                key={`${modelKey}-${i}-name`}
                className='large-col model-name-type-text'
              >
                {props.modelInformation?.[modelKey]?.public?.metadata?.name}
              </div>,
              <div
                key={`${modelKey}-${i}-model-type`}
                className='medium-col model-name-type-text'
              >
                {props.modelInformation?.[modelKey]?.public?.metadata?.modelType}
              </div>,
              <div
                key={`${modelKey}-${i}-model-ex`}
                className='large-col model-extraction-text'
              >
                {Helpers.getAsterisks(props.modelInformation?.[modelKey]?.public?.metadata?.stats?.extraction)}
              </div>,
              <div
                key={`${modelKey}-${i}-model-su`}
                className='large-col model-summarization-text'
              >
                {Helpers.getAsterisks(props.modelInformation?.[modelKey]?.public?.metadata?.stats?.summarization)}
              </div>,
              <div
                key={`${modelKey}-${i}-model-re`}
                className='large-col model-reasoning-text'
              >
                {Helpers.getAsterisks(props.modelInformation?.[modelKey]?.public?.metadata?.stats?.reasoning)}
              </div>,
              <div
                key={`${modelKey}-${i}-model-de`}
                className='large-col model-decision-text'
              >
                {Helpers.getAsterisks(props.modelInformation?.[modelKey]?.public?.metadata?.stats?.decisioning)}
              </div>,
              <div
                key={`${modelKey}-${i}-model-un`}
                className='large-col model-understanding-text'
              >
                {Helpers.getAsterisks(props.modelInformation?.[modelKey]?.public?.metadata?.stats?.understanding)}
              </div>,
            ]}
            style={{
              maxHeight: '100%',
            }}
          />
        )
      }))
    }
  }, [props.modelInformation, selectedList, filterCriteria, isFilteringUp])

  return (
    <div className='models-page'>
      {props.modelInformation &&
      <Modal2
        isVisible={isCompareModalShowing}
        style={{
          height: 'min(100vh, 800px)',
          width: 'min(100vw, 1200px)',
        }}
        hasLine={false}
        theme={props.theme}
        onDismiss={(e) => {
          setIsCompareModalShowing(false)
        }}
        direction='down'
        title={'Compare Models'}
        outsideClickDismisses={true}
        contentStyle={{height: '100%', width: '100%', boxSizing: 'border-box', position: 'relative'}}
      >
        <div className='compare-modal'>
          {selectedList.map((val, i) => {
            return (
              <div key={'compare-selected-'+i} className='compare-model-col'>
                <Select
                  onClickOption={(optionName) => {
                    selectedList[i] = Object.keys(props.modelInformation).filter((modelKey) => props.modelInformation[modelKey]?.public?.metadata?.name === optionName)[0]
                    setSelectedList(selectedList)
                  }}
                  options={Object.keys(props.modelInformation).filter((modelKey) => !selectedList.includes(modelKey)).map((modelKey, i) => {
                    return props.modelInformation[modelKey]?.public?.metadata?.name
                  })}
                  value={props.modelInformation[selectedList[i]]?.public?.metadata?.name}
                  theme={props.theme}
                  isSelectedYellow={true}
                  textMaxLength={30}
                />
                <ModelInformation
                  selectedModel={selectedList[i]}
                  showDemo={true}
                  hasAccess={props.modelAccess && props.modelAccess.includes(selectedList[i])}
                  width='100%'
                />
              </div>
            )
          })}
          {[...new Array(3 - selectedList.length)].map((val, i) => {
            return (
              <div key={'compare-'+(selectedList.length + i)} className='compare-model-col'>
                <Select
                  onClickOption={(optionName) => {
                    selectedList.push(Object.keys(props.modelInformation).filter((modelKey) => props.modelInformation[modelKey]?.public?.metadata?.name === optionName)[0])
                    setSelectedList(selectedList)
                  }}
                  options={Object.keys(props.modelInformation).filter((modelKey) => !selectedList.includes(modelKey)).map((modelKey, i) => {
                    return props.modelInformation[modelKey]?.public?.metadata?.name
                  })}
                  textMaxLength={30}
                  placeholder={'Select...'}
                  theme={props.theme}
                  isSelectedYellow={true}
                />
              </div>
            )
          })}
        </div>
      </Modal2>}
      <div className='models-left'>
        <div className='models-chart'>
          <div
            className='model-chart-table-header'
          >
            <span className='table-header'>Models</span>
            <span
              className='compare-text'
              onClick={() => {
                setIsCompareModalShowing(true)
              }}
            >
                  Compare Models
            </span>
          </div>
          <Table
            total={modelEntries.length}
            columnTitles={[
              <div
                key='head-avatar'
                className='small-col'
                onClick={() => {
                  onClickCriteria('priority')
                }}
              >
                <span>Avatar</span>
                {(filterCriteria === 'priority') &&
                  (isFilteringUp ?
                    <Icon animation={null} icon='chevron-up-outline' key='priority-up' />:
                    <Icon animation={null} icon='chevron-down-outline' key='priority-down' />)
                }
              </div>,
              <div
                key='head-name'
                className='large-col'
                onClick={() => {
                  onClickCriteria('name')
                }}
              >
                <span>Name</span>
                {(filterCriteria === 'name') &&
                  (isFilteringUp ?
                    <Icon animation={null} icon='chevron-up-outline' key='name-up' />:
                    <Icon animation={null} icon='chevron-down-outline' key='name-down' />)
                }
              </div>,
              <div
                key='head-model-type'
                className='medium-col'
                onClick={() => {
                  onClickCriteria('modelType')
                }}
              >
                <span>Model Type</span>
                {(filterCriteria === 'modelType') &&
                  (isFilteringUp ?
                    <Icon animation={null} icon='chevron-up-outline' key='modelType-up' />:
                    <Icon animation={null} icon='chevron-down-outline' key='modelType-down' />)
                }
              </div>,
              <div
                key='head-ex'
                className='large-col'
                onClick={() => {
                  onClickCriteria('stats|extraction')
                }}
              >
                <span>Extraction</span>
                {(filterCriteria === 'stats|extraction') &&
                  (isFilteringUp ?
                    <Icon animation={null} icon='chevron-up-outline' key='stats|extraction-up' />:
                    <Icon animation={null} icon='chevron-down-outline' key='stats|extraction-down' />)
                }
              </div>,
              <div
                key='head-su'
                className='large-col'
                onClick={() => {
                  onClickCriteria('stats|summarization')
                }}
              >
                <span>Summarization</span>
                {(filterCriteria === 'stats|summarization') &&
                  (isFilteringUp ?
                    <Icon animation={null} icon='chevron-up-outline' key='stats|summarization-up' />:
                    <Icon animation={null} icon='chevron-down-outline' key='stats|summarization-down' />)
                }
              </div>,
              <div
                key='head-re'
                className='large-col'
                onClick={() => {
                  onClickCriteria('stats|reasoning')
                }}
              >
                <span>Reasoning</span>
                {(filterCriteria === 'stats|reasoning') &&
                  (isFilteringUp ?
                    <Icon animation={null} icon='chevron-up-outline' key='stats|reasoning-up' />:
                    <Icon animation={null} icon='chevron-down-outline' key='stats|reasoning-down' />)
                }
              </div>,
              <div
                key='head-de'
                className='large-col'
                onClick={() => {
                  onClickCriteria('stats|decisioning')
                }}
              >
                <span>Decision-making</span>
                {(filterCriteria === 'stats|decisioning') &&
                  (isFilteringUp ?
                    <Icon animation={null} icon='chevron-up-outline' key='stats|decisioning-up' />:
                    <Icon animation={null} icon='chevron-down-outline' key='stats|decisioning-down' />)
                }
              </div>,
              <div
                key='head-un'
                className='large-col'
                onClick={() => {
                  onClickCriteria('stats|understanding')
                }}
              >
                <span>Understanding</span>
                {(filterCriteria === 'stats|understanding') &&
                  (isFilteringUp ?
                    <Icon animation={null} icon='chevron-up-outline' key='stats|understanding-up' />:
                    <Icon animation={null} icon='chevron-down-outline' key='stats|understanding-down' />)
                }
              </div>,
            ]}
            entries={modelEntries}
            theme={props.theme}
            showTotal={false}
            showCount={false}
            className='models-chart-table-wrapper no-scrollbar'
          />
        </div>
        {selectedList?.length > 0 &&
          <div className='model-code section animate-snippet no-scrollbar'>
            <h3>Code Snippet</h3>
            <p>Curl</p>
            {selectedList[selectedList.length - 1] && <>
              <Code
                language={'Curl'}
                code={
                  props.modelInformation[selectedList[selectedList.length - 1]]?.public?.metadata?.codeSnippet
                }
                theme={props.theme}
              ></Code>
              <div
                className='copy-btn'
                onClick={() => {
                  Helpers.copyToClipBoard(
                      props.modelInformation[selectedList[selectedList.length - 1]]?.public?.metadata?.codeSnippet,
                      () => {
                        props.storePayload({
                          userMessage: 'Code Snippet Copied',
                          notificationIcon: 'check',
                          notificationType: 2,
                        })
                      },
                  )
                }}
              >
                <Icon animation={null} icon='file-outline'/>
              </div>
            </>}
          </div>
        }
      </div>
      {selectedList[selectedList.length - 1] &&
        <ModelInformation
          selectedModel={selectedList[selectedList.length - 1]}
          showDemo={true}
          showAnimate={true}
          hasAccess={props.modelAccess && props.modelAccess.includes(selectedList[selectedList.length - 1])}
        />
      }
    </div>
  )
}


const mapStateToProps = (state, ownProps) => ({
  isLoading: state.userReducer.isLoading,
  theme: state.userReducer.theme,
  modelInformation: state.userReducer.modelInformation,
  modelAccess: state.userReducer.modelAccess,
})

export default connect(
    mapStateToProps,
    {storePayload},
)(Models)
