import types from '../types'
import Helpers from '../Helpers'
import endpoints from '.././endpoints.json'

export const getSubOrganizationMetrics = (database, orgId, userId) => (dispatch, getState) => {
  const userReducer = getState().userReducer
  const authKey = userReducer.authKey
  Helpers.fetchGet(
      process.env.REACT_APP_URL + endpoints.subOrgsMetrics + '?filter_demo_init=true',
      {
        'userId': userId,
        'orgId': orgId,
        'authKey': authKey,
      },
  ).then((res) => {
    if (res?.metrics) {
      dispatch({
        type: types.STORE_PAYLOAD,
        payload: {
          subOrganizationMetrics: res.metrics,
        },
      })
    } else {
      dispatch({
        type: types.STORE_PAYLOAD,
        payload: {
          subOrganizationMetrics: null,
          userMessage: `Error getting suborganization metrics`,
          notificationType: 2,
          notificationIcon: 'warning',
          isNotificationVisible: true,
        },
      })
    }
  }). catch((error) => {
    console.log(error)
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        subOrganizationMetrics: null,
        userMessage: `Error getting suborganization metrics`,
        notificationType: 2,
        notificationIcon: 'warning',
        isNotificationVisible: true,
      },
    })
  })
}
