const text = (orgId, authKey) =>
  `<?php

$curl = curl_init();

curl_setopt_array($curl, array(
  CURLOPT_URL => 'https://api.lazarusforms.com/api/forms/ocr',
  CURLOPT_RETURNTRANSFER => true,
  CURLOPT_ENCODING => '',
  CURLOPT_MAXREDIRS => 10,
  CURLOPT_TIMEOUT => 0,
  CURLOPT_FOLLOWLOCATION => true,
  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
  CURLOPT_CUSTOMREQUEST => 'POST',
  CURLOPT_POSTFIELDS => array('file'=> new CURLfile('file_HERE')),
  CURLOPT_HTTPHEADER => array(
    'orgId: ${orgId}',
    'authKey: ${authKey}'
  ),
));

$response = curl_exec($curl);

curl_close($curl);
echo $response;`

export default text
