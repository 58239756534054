const text = (orgId, authKey) =>
  `OkHttpClient client = new OkHttpClient().newBuilder()
  .build();
MediaType mediaType = MediaType.parse("application/json");
RequestBody body = RequestBody.create(mediaType, "{\"inputUrl\":\"inputUrl_HERE\",\"fileId\":\"fileId_HERE | Optional\",\"metadata\":{\"metadata_HERE | Optional\"},\"webhook\":\"webhook_HERE | Optional\"}");
Request request = new Request.Builder()
  .url("https://api.lazarusforms.com/api/forms/ocr")
  .method("POST", body)
  .addHeader("orgId", ${orgId})
  .addHeader("authKey", ${authKey})
  .addHeader("Content-Type", "application/json")
  .build();
Response response = client.newCall(request).execute();`

export default text
