const text = (orgId, authKey) =>
  `
var myHeaders = new Headers();
myHeaders.append("orgId", ${orgId});
myHeaders.append("authKey", ${authKey});

var formdata = new FormData();
formdata.append("file", 'file_HERE', 'file_name');

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: formdata,
  redirect: 'follow'
};

fetch("https://api.lazarusforms.com/api/forms/identification", requestOptions)
  .then(response => response.text())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));

  `

export default text
