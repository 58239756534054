const text = (orgId, authKey) =>
  `
  package main

  import (
    "fmt"
    "bytes"
    "mime/multipart"
    "os"
    "path/filepath"
    "io"
    "net/http"
    "io/ioutil"
  )
  
  func main() {
  
    url := "https://api.lazarusforms.com/api/rikai"
    method := "POST"
  
    payload := &bytes.Buffer{}
    writer := multipart.NewWriter(payload)
    file, errFile1 := os.Open(path_to_file)
    defer file.Close()
    part1,
           errFile1 := writer.CreateFormFile("file",filepath.Base(path_to_file))
    _, errFile1 = io.Copy(part1, file)
    if errFile1 != nil {
      fmt.Println(errFile1)
      return
    }
      _ = writer.WriteField("question", "What color is the sky?")
    err := writer.Close()
    if err != nil {
      fmt.Println(err)
      return
    }
  
  
    client := &http.Client {
    }
    req, err := http.NewRequest(method, url, payload)
  
    if err != nil {
      fmt.Println(err)
      return
    }
    req.Header.Add("orgId", ${orgId})
    req.Header.Add("authKey", ${authKey})
  
    req.Header.Set("Content-Type", writer.FormDataContentType())
    res, err := client.Do(req)
    if err != nil {
      fmt.Println(err)
      return
    }
    defer res.Body.Close()
  
    body, err := ioutil.ReadAll(res.Body)
    if err != nil {
      fmt.Println(err)
      return
    }
    fmt.Println(string(body))
  }
  `

export default text
