const text = (orgId, authKey) =>
  `
require "uri"
require "json"
require "net/http"

url = URI("https://api.lazarusforms.com/api/rikai")

https = Net::HTTP.new(url.host, url.port)
https.use_ssl = true

request = Net::HTTP::Post.new(url)
request["orgId"] = ${orgId}
request["authKey"] = ${authKey}
request["Content-Type"] = "application/json"
request.body = JSON.dump({
  "base64": "base64_string",
  "question":["What color is the sky"],
  "fileId": "fileId_HERE | Optional",
  "metadata": {"metadata_HERE | Optional"},
  "webhook": "webhook_HERE | Optional"
})

response = https.request(request)
puts response.read_body
  `

export default text
