const text = (orgId, authKey) =>
  `var client = new RestClient("https://api.lazarusforms.com/api/forms/ocr");
client.Timeout = -1;
var request = new RestRequest(Method.POST);
request.AddHeader("orgId", ${orgId});
request.AddHeader("authKey", ${authKey});
request.AddHeader("Content-Type", "application/json");
var body = @"{""inputUrl"":""inputUrl_HERE"",""fileId"":""fileId_HERE | Optional"",""metadata"":{""metadata_HERE | Optional""},""webhook"":""webhook_HERE | Optional""}";
request.AddParameter("application/json", body,  ParameterType.RequestBody);
IRestResponse response = client.Execute(request);
Console.WriteLine(response.Content);`

export default text
