const text = (orgId, authKey) =>
  `curl --location --request POST 'https://api.lazarusforms.com/api/forms/generic' \
--header 'orgId: ${orgId.slice(1, orgId.length - 1)}' \
--header 'authKey: ${authKey.slice(1, authKey.length - 1)}' \
--header 'version: 2' \
--header 'Content-Type: application/json' \
--data-raw '{"base64": "base64_string", "fileId": "abc", "metadata": {}, "webhook": "google.com"}'`

export default text

