const text = (orgId, authKey) =>
  `curl --location --request POST 'https://api.lazarusforms.com/api/forms/receipts/zip' \
 --header 'orgId: ${orgId.slice(1, orgId.length - 1)}' \
 --header 'authKey: ${authKey.slice(1, authKey.length - 1)}' \
 --header 'Content-Type: application/json' \
 --form 'file=@"/path/to/file"' \
 --form 'fileId="abc"' \
 --form 'metadata="{}"' \
 --form 'webhook="webhook_HERE"'`
export default text
