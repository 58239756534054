const text = (orgId, authKey) =>
  `
 var myHeaders = new Headers();
 myHeaders.append("orgId", ${orgId});
 myHeaders.append("authKey", ${authKey});
 
 var formdata = new FormData();
 formdata.append("file", 'file_HERE', 'file_name', "question", 'question_HERE');
 
 var requestOptions = {
   method: 'POST',
   headers: myHeaders,
   body: formdata,
   redirect: 'follow'
 };
 
 fetch("https://api.lazarusforms.com/api/rikai/custom/your_custom_model_id_HERE", requestOptions)
   .then(response => response.text())
   .then(result => console.log(result))
   .catch(error => console.log('error', error));
 `

export default text
