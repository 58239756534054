import React, {useState} from 'react'
import {connect} from 'react-redux'
import {storePayload} from '../actions/storePayload'
import {Button, Icon, InputMulti, Table, TableEntry} from '@lazarusai/forms-ui-components'
import SideTab from './SideTab'
import Helpers from '../Helpers.js'

import '../styles/SideTab.css'

function AddUsersSideTab({
  isVisible=false,
  onClose=()=>{},
  onSave=(selectedUsers)=>{}, // onSave passes the list of uids for the selected users
  headerText = 'Add User',
  showClose = true,
  hasBackground = false,
  ...props
}) {
  const [selectedUsers, setSelectedUsers] = useState([])

  function getTabContent(selectedUsers, usersObj) {
    return (
      <div className='add-users-body'>
        <div className='permission-side-section add-input-wrapper'>
          <InputMulti
            theme={props.theme}
            label={'Search Existing Users'}
            onChange={(selected) => {
              const addUsers = [...selectedUsers, selected.value]
              setSelectedUsers(addUsers.filter((uid, uIndex) => uIndex === addUsers.indexOf(uid)))
            }}
            options={Object.keys(props.usersObj).map((uid) => {
              return {value: uid, label: `${props.usersObj[uid].email}${props.usersObj[uid]?.role ? ' - '+props.usersObj[uid]?.role : ''}`}
            })}
            icon={<Icon icon='search-outline' />}
          />
        </div>
        <div
          className='permission-side-section add-users-table-wrapper'
        >
          <Table
            theme={props.theme}
            entries={selectedUsers.map((uid) => {
              const user = props.usersObj[uid]
              return (
                <TableEntry
                  key={`row-${uid}`}
                  content={[
                    <span key='email' className='text-bright-blue'>
                      {user['email']}
                    </span>,
                    <span key='role-text'>{Helpers.vkgAccessString(user)}</span>,
                    <Icon
                      key={`user-${uid}-remove`}
                      icon='trash-2-outline'
                      className='cursor-pointer remove-icon'
                      onClick={() => {
                        setSelectedUsers(selectedUsers.filter((thisUid) => uid !== thisUid))
                      }}
                    />,
                  ]}
                />
              )
            })}
            title='Users'
            columnTitles={[
              'Email',
              'Access',
              <Icon
                key='title-delete'
                className='invisible-btn'
                icon='trash-2-outline'
                onClick={() => {
                  removeVKGAccess(userId, selectedManageGraph)
                }}
              />,
            ]}
            className={'add-users-table'}
          />
        </div>
        <div
          className='permission-side-section add-user-bottom-row'
        >
          <Button
            theme={props.theme}
            type={10}
            text={'Discard'}
            onClick={() => {
              onClose()
              setSelectedUsers([])
            }}
            width={'fit-content'}
          />
          <Button
            theme={props.theme}
            type={8}
            text={'Save Changes'}
            onClick={() => {
              onSave(selectedUsers)
              setSelectedUsers([])
              onClose()
            }}
            width={'fit-content'}
          />
        </div>
      </div>
    )
  }

  return (
    <div>
      <SideTab
        isVisible={isVisible}
        onClose={() => {
          onClose()
          setSelectedUsers([])
        }}
        headerText={headerText}
        showClose={showClose}
        hasBackground={hasBackground}
        contents={getTabContent(selectedUsers, props.usersObj)}
      />
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  userMessage: state.userReducer.userMessage,
  user: state.userReducer.user,
  database: state.firebaseReducer.database,
  theme: state.userReducer.theme,
  userData: state.userReducer.userData,
  graphObj: state.userReducer.graphObj,
  usersObj: state.userReducer.usersObj,
})

export default connect(mapStateToProps, {storePayload})(
    AddUsersSideTab,
)
