import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
// import codingLanguageList from '../CodingLanguages.js'
// import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import {
  Icon,
  Table,
  TableEntry,
  Button,
  Input,
} from '@lazarusai/forms-ui-components'
import {storePayload} from '../actions/storePayload.js'
import Arrow from '../images/arrow-upward-outline.svg'

import '../styles/Roles.css'

const UserTable = (props) => {
  const [userTableData, setUserTableData] = useState([]);
  const [sortedByEmail, setSortedByEmail] = useState([]);
  const [sortedByDate, setSortedByDate] = useState([]);
  const [selectSort, setSelectSort] = useState([]);
  const [tableEntries, setTableEntries] = useState([]);
  const [userSearch, setUserSearch] = useState('');

  // Update userTableData and sorted data when props.usersObj changes
  useEffect(() => {
    if (props.usersObj) {
      const userTableDataArray = Object.keys(props.usersObj).map((key) => ({
        key,
        ...props.usersObj[key],
      }));
      setUserTableData(userTableDataArray.filter((user) => user.role === props.roleSelection));
      const sortedEmail = userTableDataArray.slice().sort((a, b) => a.email.localeCompare(b.email));
      const sortedDate = userTableDataArray.sort((a, b) => new Date(a.datetimeCreated) - new Date(b.datetimeCreated));

      setSortedByEmail(sortedEmail.filter((user) => user.role === props.roleSelection));
      setSortedByDate(sortedDate.filter((user) => user.role === props.roleSelection));
      setSelectSort(userTableDataArray);
    }
  }, [props.usersObj, props.roleSelection]);

  // Update table entries when selectSort changes
  useEffect(() => {
    if (selectSort && selectSort.length) {
      setTableEntries(
          selectSort.map((user, i) => (
            <TableEntry
              key={`${user.key}-id-${i}`}
              content={[
                <p key='email' className='light-blue'>{user.email}</p>,
                <p key='date' className='dark-gray'>{user.datetimeCreated}</p>,
                <Button
                  key='role'
                  theme={props.theme}
                  className='role-button capitalize'
                  type={8}
                  text={capitalize(user.role)}
                  icon={<Icon icon='arrow-right-outline' />}
                  iconPosition='right'
                  iconJustify='edge'
                  // onClick={() => } add function here
                />,
              ]}
            />
          )),
      );
    }
  }, [selectSort]);

  // Filter users based on search input and role selection
  useEffect(() => {
    let filteredUsers = [...userTableData];
    if (userSearch) {
      filteredUsers = filteredUsers.filter((user) => user.email.startsWith(userSearch));
    }
    if (props.roleSelection) {
      filteredUsers = filteredUsers.filter((user) => user.role === props.roleSelection);
    }
    setSelectSort(filteredUsers);
  }, [userSearch, props.roleSelection, userTableData]);

  // Capitalize string utility function
  const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);

  const table = (
    <>
      <br></br>
      <span>
        <Input
          inputType='text'
          value={userSearch}
          onChange={(e) => setUserSearch(e.target.value)}
          theme={props.theme}
          placeholder='Search'
          width='60%'
        />
        <Button
          style={{marginLeft: 'auto'}}
          buttonType='submit'
          text='Add User'
          theme={props.theme}
          width='10%'
          onClick={() => setIsVisible(true)}
        />
      </span>
      <br></br>
      <Table
        theme={props.theme}
        label={userSearch}
        title={props.roleSelection}
        content='Default roles come with set access and permissions. Default settings cannot be changed.'
        columnTitles={[
          <Button
            text={'Email'}
            key='email'
            iconImage={Arrow}
            theme={props.theme}
            onClick={() => setSelectSort(sortedByEmail)}
          >
          </Button>,
          <Button
            text='Date Added'
            key='date'
            iconImage={Arrow}
            theme={props.theme}
            onClick={() => setSelectSort(sortedByDate)}
          >
          </Button>,
          <Button
            text='User Group'
            key='user-group'
            theme={props.theme}
            disabled={true}
          >
          </Button>,
        ]}
        showCount={true}
        useFilter={false}
        entries={tableEntries}
        maxHeight='50rem'
      />
    </>
  );

  return (
    <div>
      {table}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  cookies: ownProps.cookies,
  storage: state.firebaseReducer.storage,
  database: state.firebaseReducer.database,
  firestore: state.firebaseReducer.firestore,
  usersObj: state.userReducer.usersObj,
  users: state.userReducer.users,
  userData: state.userReducer.userData,
  user: state.userReducer.user,
  orgDetails: state.userReducer.orgDetails,
  orgId: state.userReducer.orgId,
  subOrgs: state.userReducer.subOrgs,
  orgActionLogs: state.userReducer.orgActionLogs,
  customerPortal: state.userReducer.customerPortal,
  billing: state.userReducer.billing,
  theme: state.userReducer.theme,
  analytics: state.firebaseReducer.analytics,
  profileChangesMade: state.userReducer.profileChangesMade,
  preventNavigationListener: state.userReducer.preventNavigationListener,
  vkgDomain: state.userReducer.vkgDomain,
})

export default connect(mapStateToProps, {
  storePayload,
})(UserTable)
