const text = (orgId, authKey) => {
  const orgIdFormatted = orgId.slice(1, orgId.length - 1)
  const authKeyFormatted = authKey.slice(1, authKey.length - 1)
  return `
 CURL *curl;
 CURLcode res;
 curl = curl_easy_init();
 if(curl) {
   curl_easy_setopt(curl, CURLOPT_CUSTOMREQUEST, "POST");
   curl_easy_setopt(curl, CURLOPT_URL, "https://api.lazarusforms.com/api/forms/invoices");
   curl_easy_setopt(curl, CURLOPT_FOLLOWLOCATION, 1L);
   curl_easy_setopt(curl, CURLOPT_DEFAULT_PROTOCOL, "https");
   struct curl_slist *headers = NULL;
   headers = curl_slist_append(headers, "orgId: ${orgIdFormatted}");
   headers = curl_slist_append(headers, "authKey: ${authKeyFormatted}");
   headers = curl_slist_append(headers, "Content-Type: application/json");
   curl_easy_setopt(curl, CURLOPT_HTTPHEADER, headers);
   const char *data = "{\"base64\":\"base64_string\",\"fileId\":\"abc\",\"metadata\":{},\"webhook\":\"webhook_HERE\"}";
   curl_easy_setopt(curl, CURLOPT_POSTFIELDS, data);
   res = curl_easy_perform(curl);
 }
 curl_easy_cleanup(curl);
 `
}

export default text
