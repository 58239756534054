const text = (orgId, authKey) =>
  `package main

import (
  "fmt"
  "strings"
  "net/http"
  "io/ioutil"
)

func main() {

  url := "https://api.lazarusforms.com/api/forms/ssn"
  method := "POST"

  payload := strings.NewReader(\`{"inputUrl": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf", "fileId": "fileId_HERE | Optional", "metadata": {"metadata_HERE | Optional"}, "webhook": "webhook_HERE | Optional"}\`)

  client := &http.Client {
  }
  req, err := http.NewRequest(method, url, payload)

  if err != nil {
    fmt.Println(err)
    return
  }
  req.Header.Add("orgId", ${orgId})
  req.Header.Add("authKey", ${authKey})
  req.Header.Add("Content-Type", "application/json")

  res, err := client.Do(req)
  if err != nil {
    fmt.Println(err)
    return
  }
  defer res.Body.Close()

  body, err := ioutil.ReadAll(res.Body)
  if err != nil {
    fmt.Println(err)
    return
  }
  fmt.Println(string(body))
}
`

export default text
