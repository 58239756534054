const text = (orgId, authKey) =>
  `
 import requests

 url = "https://api.lazarusforms.com/api/forms/receipts"
 
 payload={}
 
 files=[
   ('file',('file_name',open('file_HERE','rb'),'application/octet-stream'))
 ]
 
 payload = json.dumps({
   "inputUrl": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
   "fileId": "fileId_HERE | Optional",
   "metadata": {"metadata_HERE | Optional"},
   "webhook": "webhook_HERE | Optional"
 })
 headers = {
   'orgId': ${orgId},
   'authKey': ${authKey},
   'Content-Type': 'application/json'
 }
 
 response = requests.request("POST", url, headers=headers, data=payload, files=files)
 
 print(response.json())
 `

export default text
