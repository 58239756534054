const text = (orgId, authKey) =>
  `class Program
    {
        private static string endpoint = "https://api.lazarusforms.com/api";
        private static string orgId = ${orgId};
        private static string authKey = ${authKey};

        static async Task Main(string[] args)
        {
            await Test();
        }

        private static async Task Test()
        {
            try
            {
                var client = new RestClient(endpoint);
                var request = new RestRequest("/forms/ocr", Method.Post);
                request.Timeout = -1;
                request.AddHeader("Content-Type", "multipart/form-data");
                request.AddHeader("orgId", ${orgId});
                request.AddHeader("authKey",  ${authKey});
                request.AddFile("file", path_to_file);
                RestResponse response = await client.ExecutePostAsync(request);
                Console.WriteLine(response.Content);
           }
            catch(Exception ex)
            {
                Console.WriteLine(ex.Message);
            }
        }
    }`

export default text
