const text = (orgId, authKey) => {
  const orgIdFormatted = orgId.slice(1, orgId.length - 1)
  const authKeyFormatted = authKey.slice(1, authKey.length - 1)
  return `
 <?php

 $curl = curl_init();
 
 curl_setopt_array($curl, array(
   CURLOPT_URL => 'https://api.lazarusforms.com/api/forms/receipts',
   CURLOPT_RETURNTRANSFER => true,
   CURLOPT_ENCODING => '',
   CURLOPT_MAXREDIRS => 10,
   CURLOPT_TIMEOUT => 0,
   CURLOPT_FOLLOWLOCATION => true,
   CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
   CURLOPT_CUSTOMREQUEST => 'POST',
   CURLOPT_POSTFIELDS =>'{"inputUrl": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf", "fileId": "abc", "metadata": {}, "webhook": "google.com"}',
   CURLOPT_HTTPHEADER => array(
     'orgId: ${orgIdFormatted}',
     'authKey: ${authKeyFormatted}',
     'Content-Type: application/json'
   ),
 ));
 
 $response = curl_exec($curl);
 
 curl_close($curl);
 echo $response;
`
}

export default text
