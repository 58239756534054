const text = (orgId, authKey) =>
  `
require "uri"
require "json"
require "net/http"

url = URI("https://api.lazarusforms.com/api/forms/receipts")

https = Net::HTTP.new(url.host, url.port)
https.use_ssl = true

request = Net::HTTP::Post.new(url)
request["orgId"] = ${orgId}
request["authKey"] = ${authKey}
request["Content-Type"] = "application/json"
request.body = JSON.dump({
  "inputUrl": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf", 
  "fileId": "fileId_HERE | Optional",
  "metadata": {"metadata_HERE | Optional"},
  "webhook": "webhook_HERE | Optional",
  "outputUrl": "output_url_HERE",
  "outputUrlHeaders": {"{"header_name":"header_value"}"}
})

response = https.request(request)
puts response.read_body
 `

export default text
