const text = (orgId, authKey) =>
  `import requests

url = "https://api.lazarusforms.com/api/forms/generic"

payload={}

files=[
  ('file',('file_name',open('path_to_file','rb'),'application/octet-stream'))
]

headers = {
  'orgId': ${orgId},
  'authKey': ${authKey},
  'version': '2'
}

response = requests.request("POST", url, headers=headers, data=payload, files=files)

print(response.json())`

export default text
