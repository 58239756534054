const text = (orgId, authKey) => {
  /* Use formatted orgId and authKey because it doesn't need to have "" */
  const orgIdFormatted = orgId.slice(1, orgId.length - 1)
  const authKeyFormatted = authKey.slice(1, authKey.length - 1)
  return `
    CURL *curl;
    CURLcode res;
    curl = curl_easy_init();
    if(curl) {
      curl_easy_setopt(curl, CURLOPT_CUSTOMREQUEST, "POST");
      curl_easy_setopt(curl, CURLOPT_URL, "https://api.lazarusforms.com/api/rikai");
      curl_easy_setopt(curl, CURLOPT_FOLLOWLOCATION, 1L);
      curl_easy_setopt(curl, CURLOPT_DEFAULT_PROTOCOL, "https");
      struct curl_slist *headers = NULL;
      headers = curl_slist_append(headers, "orgId: ${orgIdFormatted}");
      headers = curl_slist_append(headers, "authKey: ${authKeyFormatted}");
      curl_easy_setopt(curl, CURLOPT_HTTPHEADER, headers);
      curl_mime *mime;
      curl_mimepart *part;
      mime = curl_mime_init(curl);
      part = curl_mime_addpart(mime);
      curl_mime_name(part, "file");
      curl_mime_filedata(part, "file_name");
      curl_mime_name(part, "question");
      curl_mime_data(part, "What color is the sky?", CURL_ZERO_TERMINATED);
      curl_easy_setopt(curl, CURLOPT_MIMEPOST, mime);
      res = curl_easy_perform(curl);
      curl_mime_free(mime);
    }
    curl_easy_cleanup(curl);
    `
}

export default text
