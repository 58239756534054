const text = (orgId, authKey) =>
  `var client = new RestClient("https://api.lazarusforms.com/api/forms/ssn");
client.Timeout = -1;
var request = new RestRequest(Method.POST);
request.AddHeader("orgId", ${orgId});
request.AddHeader("authKey", ${authKey});
request.AddFile("file", "file_HERE");
IRestResponse response = client.Execute(request);
Console.WriteLine(response.Content);`

export default text
