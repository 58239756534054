const text = (orgId, authKey) =>
  `
import Foundation
#if canImport(FoundationNetworking)
import FoundationNetworking
#endif

var semaphore = DispatchSemaphore (value: 0)

let parameters = "{\"inputUrl\":\"https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf\",\"fileId\":\"abc\",\"metadata\":{},\"webhook\":\"google.com\"}"
let postData = parameters.data(using: .utf8)

var request = URLRequest(url: URL(string: "https://api.lazarusforms.com/api/forms/receipts/zip")!,timeoutInterval: Double.infinity)
request.addValue(${orgId}, forHTTPHeaderField: "orgId")
request.addValue(${authKey}, forHTTPHeaderField: "authKey")
request.addValue("application/json", forHTTPHeaderField: "Content-Type")

request.httpMethod = "POST"
request.httpBody = postData

let task = URLSession.shared.dataTask(with: request) { data, response, error in 
  guard let data = data else {
    print(String(describing: error))
    semaphore.signal()
    return
  }
  print(String(data: data, encoding: .utf8)!)
  semaphore.signal()
}

task.resume()
semaphore.wait()
 `

export default text
