import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
// import codingLanguageList from '../CodingLanguages.js'
// import firebase from 'firebase/compat/app'
import UserTable from './UserTable'
import AddUsersSideTab from './AddUsersSideTab.jsx'
import 'firebase/compat/auth'
import {
  Icon,
  Table,
  TableEntry,
  TabContainer,
  Button,
  Input,
  Card,
  CollapseCard,
  Switch,
} from '@lazarusai/forms-ui-components'
import {storePayload} from '../actions/storePayload.js'
import {enterMFACode} from '../actions/enterMFACode'
import {setDefaultLanguage} from '../actions/setDefaultLanguage.js'
import {setNames} from '../actions/setNames.js'
import {removeAnalytics} from '../actions/removeAnalytics.js'
import {initializeAnalytics} from '../actions/initializeAnalytics.js'

import '../styles/Roles.css'

function Roles(props) {
  const [roleSelection, setRoleSelection] = useState('admin')
  const [changeTab, setChangeTab] = useState(false)
  const [roleTabName, setRoleTabName] = useState('general')
  const [userRolesTableEntry, setUserRolesTableEntry] = useState({'admin': null, 'intermediate': null, 'basic': null, 'custom': null})
  const [isVisible, setIsVisible] = useState(false);
  const handleProfileChanges = () => {
    if (props.profileChangesMade) {
      if (window.confirm('You have unsaved changes. Are you sure you want to continue?')) {
        props.storePayload({
          profileChangesMade: false,
        })
        window.removeEventListener('beforeunload', props.preventNavigationListener)
      } else {
        return false
      }
    }
    return true
  }

  function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  useEffect(() => {
    const userData = {'admin': 0, 'intermediate': 0, 'basic': 0, 'custom': 0}
    // Update userRoles state
    Object.keys(props.usersObj).forEach((key) => {
      const role = props.usersObj[key].role;
      if (Object.keys(userData).includes(role)) {
        userData[role] = userData[role] + 1
      };
    })
    // Update userRolesTableEntry state
    const updatedTableEntry = {};
    Object.keys(userData).forEach((key) => {
      const tableEntry = `${userData[key]} users`
      updatedTableEntry[key] = tableEntry;
    });
    // console.log('updatedTableEntry', updatedTableEntry)
    // console.log('userRoles:', userData);
    // console.log('userRolesTableEntry:', userRolesTableEntry);
    setUserRolesTableEntry(updatedTableEntry);
  }, [props.usersObj]); // Dependency array


  const sidebar = (
    <AddUsersSideTab isVisible={isVisible} onClose={() => setIsVisible(false)} />
  );

  const rolesTab = (
    <div
      name='Role'
      className='tab-content show-scrollbar'
    >
      <div style={{padding: 12 + 'px'}}>
        <h1>Roles</h1>
        <p> User roles help you set up permissions in a consistent manner.</p>
      </div>
      <Table
        theme={props.theme}
        title='Default Roles'
        content='Default roles come with set access and permissions. Default settings cannot be changed.'
        style={{minHeight: 'auto'}}
        columnTitles={['Role', 'Users']}
        showCount={false}
        entries={[
          <TableEntry
            key={'admin-role'}
            className='roles-table-entry'
            content={[
              <div key='entry' className='description'>
                <h4>Admin</h4>
                  Description of what that entails goes here. Description of what that entails goes here. Description of what that entails goes here. Description of what that entails goes here.
              </div>,
              <div key='entry2' className='user-button'>
                {userRolesTableEntry['admin']}
                <Button
                  onClick={() => {
                    setChangeTab(true)
                    setRoleSelection('admin')
                  }}
                  style={{marginLeft: 'auto'}}
                  buttonType='submit'
                  text='View Details'
                  theme={props.theme}
                  width='50%'
                />
              </div>,
            ]}
          />,
          <TableEntry
            key={'intermediate-role'}
            className='roles-table-entry'
            content={[
              <div key='entry' className='description'>
                <h4>Intermediate</h4>
                  Description of what that entails goes here. Description of what that entails goes here. Description of what that entails goes here. Description of what that entails goes here.
              </div>,
              <div key='entry2' className='user-button'>
                {userRolesTableEntry['intermediate']}
                <Button
                  onClick={() => {
                    setChangeTab(true)
                    setRoleSelection('intermediate')
                  }}
                  style={{marginLeft: 'auto'}}
                  buttonType='submit'
                  text='View Details'
                  theme={props.theme}
                  width='50%'
                />
              </div>,
            ]}
          />,
          <TableEntry
            key={'basic-entry'}
            className='roles-table-entry'
            content={[
              <div key='entry' className='description'>
                <h4>Basic</h4>
                  Description of what that entails goes here. Description of what that entails goes here. Description of what that entails goes here. Description of what that entails goes here.
              </div>,
              <div key='entry2' className='user-button'>
                {userRolesTableEntry['basic']}
                <Button
                  onClick={() => {
                    setChangeTab(true)
                    setRoleSelection('basic')
                  }}
                  style={{marginLeft: 'auto'}}
                  buttonType='submit'
                  text='View Details'
                  theme={props.theme}
                  width='50%'
                />
              </div>,
            ]}
          />,
          <TableEntry
            key={'custom-role'}
            className='roles-table-entry'
            content={[
              <div key='entry' className='description'>
                <h4><i>Custom</i></h4>
                  Description of what that entails goes here. Description of what that entails goes here. Description of what that entails goes here. Description of what that entails goes here.
              </div>,
              <div key='entry2' className='user-button'>
                {userRolesTableEntry['custom']}
                <Button
                  onClick={() => {
                    setChangeTab(true)
                    setRoleSelection('custom')
                  }}
                  style={{marginLeft: 'auto'}}
                  buttonType='submit'
                  text='View Details'
                  theme={props.theme}
                  width='50%'
                />
              </div>,
            ]}
          />,
        ]}
      />
      <div></div>
    </div>
  )
  /* Breadcrumb header at the top of page. Shows previous & current pages */
  const breadcrumb = (pageName) => {
    return (
      <span>
        <p className='bright-green'>Roles</p> <p>/ {pageName}</p>
      </span>
    )
  }

  const assignedUsersTab = (
    <UserTable roleSelection={roleSelection}></UserTable>
  )
  const generalTab = (
    <div>
      <Card
        title={capitalize(roleSelection)}
        content='Last updated: 2024-01-06'
        theme={props.theme}
      >
        <Input
          label='Group Name: '
          placeholder={capitalize(roleSelection)}
          width='50%'
          value={capitalize(roleSelection)}
          theme={props.theme}
          type={1}
        />

        <Input
          label='Description: '
          placeholder={'i am a description'}
          width='50%'
          value={'i am a description'}
          theme={props.theme}
          type={1}
        />
        <h2>Permissions</h2>
        <p>Default permissions for this group.</p>
        <div className='dark-gray-background'>
          <CollapseCard
            title='Billing and Credits'>
            <hr></hr>
            <br></br>
            <span className='single-line'>
              <div>
                <h3>View/manage billing</h3>
                <p>Description of what that entails. Description of what that entails. Description of what that entails.</p>
              </div>
              <div className='toggle-right-align'>
                <Switch
                  theme={props.theme}
                  disabled={roleSelection!='custom'}>
                </Switch>
              </div>
            </span>
            <br></br><br></br>
            <span className='single-line'>
              <div>
                <h3>Use Demo Credit</h3>
                <p>Description of what that entails. Description of what that entails. Description of what that entails.</p>
              </div>
              <div className='toggle-right-align'>
                <Switch
                  theme={props.theme}
                  disabled={roleSelection!='custom'}>
                </Switch>
              </div>
            </span>
          </CollapseCard>
        </div>
        <CollapseCard
          title='Admin'>
          <hr></hr><br></br>
          <span className='single-line'>
            <div>
              <h3>Set user permissions</h3>
              <p>Description of what that entails. Description of what that entails. Description of what that entails.</p>
            </div>
            <div className='toggle-right-align'>
              <Switch
                theme={props.theme}
                disabled={roleSelection!='custom'}>
              </Switch>
            </div>
          </span>
          <br></br><br></br>
          <span className='single-line'>
            <div>
              <h3>Invite new users</h3>
              <p>Description of what that entails. Description of what that entails. Description of what that entails.</p>
            </div>
            <div className='toggle-right-align'>
              <Switch
                theme={props.theme}
                disabled={roleSelection!='custom'}>
              </Switch>
            </div>
          </span>
          <br></br><br></br>
          <span className='single-line'>
            <div>
              <h3>Reset authkey</h3>
              <p>Description of what that entails. Description of what that entails. Description of what that entails.</p>
            </div>
            <div className='toggle-right-align'>
              <Switch
                theme={props.theme}
                disabled={roleSelection!='custom'}>
              </Switch>
            </div>
          </span>
          <br></br><br></br>
          <span className='single-line'>
            <div>
              <h3>View credentials</h3>
              <p>Description of what that entails. Description of what that entails. Description of what that entails.</p>
            </div>
            <div className='toggle-right-align'>
              <Switch
                theme={props.theme}
                disabled={roleSelection!='custom'}>
              </Switch>
            </div>
          </span>
        </CollapseCard>
        <CollapseCard
          title='Log Data'>
          <hr></hr><br></br>
          <span className='single-line'>
            <div>
              <h3>View basic log data</h3>
              <p>Description of what that entails. Description of what that entails. Description of what that entails.</p>
            </div>
            <div className='toggle-right-align'>
              <Switch
                theme={props.theme}
                disabled={roleSelection!='custom'}>
              </Switch>
            </div>
          </span>
          <br></br><br></br>
          <span className='single-line'>
            <div>
              <h3>View audit log data</h3>
              <p>Description of what that entails. Description of what that entails. Description of what that entails.</p>
            </div>
            <div className='toggle-right-align'>
              <Switch
                theme={props.theme}
                disabled={roleSelection!='custom'}>
              </Switch>
            </div>
          </span>
          <br></br><br></br>
          <span className='single-line'>
            <div>
              <h3>Download log data</h3>
              <p>Description of what that entails. Description of what that entails. Description of what that entails.</p>
            </div>
            <div className='toggle-right-align'>
              <Switch
                theme={props.theme}
                disabled={roleSelection!='custom'}>
              </Switch>
            </div>
          </span>
        </CollapseCard>
        <CollapseCard
          title='Vector Knowledge Graphs'>
          <hr></hr><br></br>
          <span className='single-line'>
            <div>
              <h3>Read VKGs</h3>
              <p>Get graphs and nodes, search VKG, use RikYChat, and export VKGs.</p>
            </div>
            <div className='toggle-right-align'>
              <Switch
                theme={props.theme}
                disabled={roleSelection!='custom'}>
              </Switch>
            </div>
          </span>
          <br></br><br></br>
          <span className='single-line'>
            <div>
              <h3>Write VKGs</h3>
              <p>Create VKGs, add, edit, and delete nodes, delete VKGs, compute TSNE, and duplicate VKGs.</p>
            </div>
            <div className='toggle-right-align'>
              <Switch
                theme={props.theme}
                disabled={roleSelection!='custom'}>
              </Switch>
            </div>
          </span>
          <br></br><br></br>
          <span className='single-line'>
            <div>
              <h3>Full VKG Server Access</h3>
              <p>Access to all VKGs within an organization instance.</p>
            </div>
            <div className='toggle-right-align'>
              <Switch
                theme={props.theme}
                disabled={roleSelection!='custom'}>
              </Switch>
            </div>
          </span>
          <br></br><br></br>
          <span className='single-line'>
            <div>
              <h3>Graph-Specific Access Only</h3>
              <p>Description of what that entails. Description of what that entails. Description of what that entails.</p>
            </div>
            <div className='toggle-right-align'>
              <Switch
                theme={props.theme}
                disabled={roleSelection!='custom'}>
              </Switch>
            </div>
          </span>
        </CollapseCard>
      </Card>
    </div>
  )
  const manageRolesTab = (
    <div
      name='Manage Roles'
      className='tab-content show-scrollbar'
    >
      {breadcrumb('Manage Role')}
      <div style={{padding: 12 + 'px'}}>
        <h1>Manage Role</h1>
        <p> User roles help you set up permissions in a consistent manner.</p>
      </div>

      <Input
        onChange={(e) => {
          setRoleSelection(e.target.value)
        }}
        label='Manage Role: '
        placeholder='None'
        width='50%'
        value={roleSelection}
        theme={props.theme}
        required
        type={3}
        options={Object.keys(userRolesTableEntry)}
        iconLeft={<Icon icon='menu-outline' className='profile-left-icon'/>}
      />

      <TabContainer
        theme={props.theme}
        className='tabs'
        activeTab={roleTabName}
        canChangeTab={handleProfileChanges}
        style={{minHeight: '50rem'}}
        onTabChange={(tabName) => {
          setRoleTabName(tabName)
        }}
        tabContent={{
          general: {
            render: generalTab,
            isDisabled: false,
            title: 'General',
          },
          assignedUsers: {
            render: assignedUsersTab,
            isDisabled: false,
            title: 'Assigned Users',
          },
        }}
      />
    </div>
  )

  return (
    <div>
      {!changeTab ? rolesTab : manageRolesTab }
      {sidebar}
    </div>
  )
}

const mapStateToProps = (state) => ({
  database: state.firebaseReducer.database,
  user: state.userReducer.user,
  orgId: state.userReducer.orgId,
  orgDetails: state.userReducer.orgDetails,
  userData: state.userReducer.userData,
  theme: state.userReducer.theme,
  isSaveCred: state.userReducer.isSaveCred,
  profileChangesMade: state.userReducer.profileChangesMade,
  usersObj: state.userReducer.usersObj,
})

export default connect(mapStateToProps, {
  storePayload,
  setDefaultLanguage,
  setNames,
  removeAnalytics,
  initializeAnalytics,
  enterMFACode,
})(Roles)
