const text = (orgId, authKey) =>
  `
<?php
$client = new Client();
$headers = [
  'orgId' => ${orgId},
  'authKey' => ${authKey}
];
$options = [
  'multipart' => [
    [
      'name' => 'file',
      'contents' => Utils::tryFopen(file path, 'r'),
      'filename' => your file name,
      'headers'  => [
        'Content-Type' => '<Content-type header>'
      ]
    ],
    [
      'name' => 'question',
      'contents' => 'What color is the sky?'
    ]
]];
$request = new Request('POST', 'https://api.lazarusforms.com/api/rikai', $headers);
$res = $client->send($request, $options);
echo $res->getBody();
  `

export default text
