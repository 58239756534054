const text = (orgId, authKey) =>
  `
OkHttpClient client = new OkHttpClient().newBuilder()
 .build();
MediaType mediaType = MediaType.parse("application/json");
RequestBody body = RequestBody.create(mediaType, "{\"inputUrl\":\"https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf\",\"fileId\":\"abc\",\"metadata\":{},\"webhook\":\"google.com\",\"outputUrl\":\"youtube.com\",\"outputUrlHeaders\":{\"{"header_name":"header_value"}\"}}");
Request request = new Request.Builder()
 .url("https://api.lazarusforms.com/api/forms/receipts")
 .method("POST", body)
 .addHeader("orgId", ${orgId})
 .addHeader("authKey", ${authKey})
 .addHeader("Content-Type", "application/json")
 .build();
Response response = client.newCall(request).execute();
 `

export default text
