const text = (orgId, authKey) =>
  `import requests
import json

url = "https://api.lazarusforms.com/api/forms/generic"

payload = json.dumps({
  "inputUrl": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
  "fileId": "fileId_HERE | Optional",
  "metadata": {"metadata_HERE | Optional"},
  "webhook": "webhook_HERE | Optional"
})
headers = {
  'orgId': ${orgId},
  'authKey': ${authKey},
  'version':'2',
  'Content-Type': 'application/json'
}

response = requests.request("POST", url, headers=headers, data=payload)

print(response.text)`

export default text
